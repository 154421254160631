<template>
    <div>
      <select v-model="selectedItem1" @change="handleChange1">
        <option value="">请选择...</option>
        <option v-for="(item, index) in items" :key="'item-'+index" :value="item">{{item.title}}</option>
      </select>
      <select v-model="selectedItem2" @change="handleChange2" :disabled="!subItems.length">
        <option value="">请选择...</option>
        <option v-for="(subItem, index) in subItems" :key="'subItem-'+index" :value="subItem">{{subItem}}</option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Dropdown',
    data() {
      return {
        selectedItem1: '',
        selectedItem2: '',
        items: [
          {
            title: 'xxx',
            data: ['1', '2', '3'],
          },
          {
            title: 'xyy',
            data: ['4', '5', '6'],
          },
        ],
        subItems: [],
      };
    },
    methods: {
      handleChange1(e) {
        console.log(e)
        // 第一级下拉框选中项发生变化，更新第二级下拉框的选项
        const selectedItem1 = this.selectedItem1;
        const selectedItem1Index = this.items.findIndex(item => item.title === selectedItem1);
        this.subItems = selectedItem1Index >= 0 ? this.items[selectedItem1Index].data : [];
        // 重置第二级下拉框选中项
        this.selectedItem2 = '';
      },
      handleChange2() {
        // 第二级下拉框选中项发生变化，可以进行其他操作
        console.log(this.selectedItem1, this.selectedItem2);
      },
    },
  };
  </script>